import * as React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player/vimeo";
import LazyLoad from "react-lazyload";
import { Box, IconButton, useMediaQuery, ButtonBase } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import PauseIcon from "@mui/icons-material/Pause";
import VideoProgress from "./VideoProgress";

import PlayIcon from "../../icons/PlaySoloIcon";

export default function EventVideo({
  url,
  progressBackgroundColor,
  isSquare,
  size,
}) {
  const { language } = useSelector((state) => state.i18n);

  const breakpointSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const breakpointMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const breakpointLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [duration, setDuration] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [play, setPlay] = React.useState(false);
  const [ended, setEnded] = React.useState(false);

  if (!url) return null;

  const handlePlay = () => {
    setPlay(!play);
    setEnded(false);
  };

  const handleEnded = () => {
    setPlay(false);
    setEnded(true);
  };

  const handleDuration = (value) => {
    setDuration(value);
  };

  const handleProgress = (newValue) => {
    if (duration) {
      const normalize = (value) => (value * 100) / duration;
      setProgress(normalize(newValue.playedSeconds));
    }
  };

  const widthHeight = breakpointLg
    ? 350
    : breakpointSm && size === "small"
    ? 250
    : breakpointSm && size !== "small"
    ? 300
    : "65vW";

  return (
    <LazyLoad>
      <Box
        dataTestId="event-video"
        sx={{
          width: widthHeight,
          height: widthHeight,
          borderRadius: isSquare ? 4 : "100%",
          overflow: "hidden",
          position: "relative",
          mb: 4,
          "&:hover": {
            "& button": {
              display: "block",
            },
          },
        }}
      >
        {!breakpointMd ? (
          <Box sx={{ position: "relative" }}>
            <ButtonBase
              onClick={handlePlay}
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                width: widthHeight,
                height: widthHeight,
              }}
            />
            <ReactPlayer
              url={url}
              width={widthHeight}
              height={widthHeight}
              playing={play}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onEnded={handleEnded}
              volume={0.8}
              onClick={handlePlay}
            />
          </Box>
        ) : (
          <>
            <ReactPlayer
              url={url}
              width={widthHeight}
              height={widthHeight}
              playing={play}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onEnded={handleEnded}
              volume={0.8}
            />
            <IconButton
              onClick={handlePlay}
              aria-label={language === "de" ? "Spiel pause" : "play pause"}
              size="large"
              color="primary"
              sx={{
                display: play ? "none" : "block",
                position: "absolute",
                bottom: {
                  xs: "40%",
                  sm: size === "small" ? 95 : 125,
                  lg: 150,
                },
                left: { xs: "44%", sm: size === "small" ? 105 : 125, lg: 150 },
                p: 0,
                transition: "all .2s ease-in-out",
                background: "rgba(255, 255, 255, 0)",
                "&:hover": {
                  background: "rgba(255, 255, 255, 0)",
                  transform: "scale(1.1)",
                },
              }}
            >
              {play ? (
                <PauseIcon sx={{ width: 50, height: 50 }} />
              ) : ended ? (
                <ReplayIcon sx={{ width: 50, height: 50 }} />
              ) : (
                <PlayIcon sx={{ width: 50, height: 50 }} />
              )}
            </IconButton>
          </>
        )}
      </Box>

      <Box sx={{ width: 200, margin: "0 auto" }}>
        <VideoProgress
          value={progress}
          progressBackgroundColor={progressBackgroundColor}
        />
      </Box>
    </LazyLoad>
  );
}

EventVideo.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  progressBackgroundColor: PropTypes.string,
  isSquare: PropTypes.bool,
};
