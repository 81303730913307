import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

export default function Paragraph({
  text,
  textVariant,
  quoteColor,
  marginBottom,
  ...props
}) {
  const splitValue = text?.includes("\r\n\r\n")
    ? "\r\n\r\n"
    : text?.includes("\\r\\n")
    ? "\\r\\n"
    : "\r\n";

  return (
    <Box {...props}>
      {text?.split(splitValue).map((elem) => (
        <Typography
          key={elem}
          sx={{
            mb: marginBottom || 1,
            fontStyle: elem[0] === '"' ? "italic" : "normal",
            fontWeight: elem[0] === '"' ? 700 : 500,
            color: (theme) =>
              elem[0] === '"' && quoteColor
                ? quoteColor
                : textVariant === "light"
                ? theme.color.white
                : textVariant === "primary"
                ? theme.palette.primary.main
                : theme.palette.text.primary,
          }}
        >
          {elem}
        </Typography>
      ))}
    </Box>
  );
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  textVariant: PropTypes.oneOf(["light", "dark", "primary"]),
};
