import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Typography, Box } from "@mui/material";
import Paragraph from "../ui/Paragraph";
import { useSelector } from "react-redux";

export default function Slider1() {
  const { language } = useSelector((state) => state.i18n);

  const {
    eveema: { sectionSlider },
  } = useStaticQuery(graphql`
    query slider1Query {
      eveema {
        sectionSlider {
          slide1Text {
            de
            en
          }
          slide1Title {
            de
            en
          }
          slide1Image
        }
      }
    }
  `);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        flexWrap: { xs: "wrap", sm: "nowrap" },
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          flexBasis: { xs: "100%", sm: "60%", md: "45%" },
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: { xs: 16, sm: 28, md: 42 },
            lineHeight: "42px",
            mb: 2,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {sectionSlider.slide1Title[language]}
        </Typography>

        <Paragraph
          textVariant="primary"
          text={sectionSlider.slide1Text[language]}
        />
      </Box>
      <Box
        sx={{
          height: { xs: "auto", sm: "100%" },
          display: "flex",
          alignItems: "flex-end",
          flexBasis: { xs: "100%", sm: "40%" },
        }}
      >
        <Box
          src={sectionSlider.slide1Image}
          alt="slide 1 image"
          component="img"
          sx={{ width: "100%", height: "auto" }}
        />
      </Box>
    </Box>
  );
}
