import * as React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Box, ButtonBase, Typography } from "@mui/material";
import LazyLoad from "react-lazyload";

import { formatLink } from "../utils/formatLink";
import SectionContainer from "../ui/SectionContainer";
import { useSelector } from "react-redux";

export default function SupporterSection({ supporters }) {
  const { language } = useSelector((state) => state.i18n);

  const {
    eveema: { pageSupporters, sectionSupporter },
  } = useStaticQuery(graphql`
    query supporterSectionQuery {
      eveema {
        pageSupporters {
          navigationTitle {
            de
            en
          }
        }
        sectionSupporter {
          title {
            de
            en
          }
          subtitle {
            de
            en
          }
          subtitleColor
        }
      }
    }
  `);
  const { title, subtitle, subtitleColor } = sectionSupporter;

  const handleLink = (link) => {
    navigate(
      `/${formatLink(pageSupporters.navigationTitle[language])}/${formatLink(
        link
      )}`
    );
  };

  return (
    <SectionContainer>
      <Box sx={{ mb: 8 }}>
        <Typography variant="h2" align="center" gutterBottom>
          {title[language]}
        </Typography>
        <Typography
          variant="subtitle1"
          component="p"
          align="center"
          sx={{
            color: (theme) =>
              subtitleColor
                ? theme.palette[subtitleColor].main
                : theme.palette.text.primary,
          }}
        >
          {subtitle[language]}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {supporters?.map((elem) => (
          <ButtonBase
            key={elem.company}
            onClick={() => handleLink(elem.company)}
            sx={{ flexBasis: { xs: "50%", sm: "auto" } }}
            aria-label={elem.company}
          >
            {elem.logo ? (
              <LazyLoad>
                <Box
                  component="img"
                  src={elem.logo}
                  alt={elem.company}
                  sx={{
                    height: { xs: 25, sm: 50 },
                    width: "auto",
                    mr: { xs: 0, sm: 4 },
                    ml: { xs: 0, sm: 4 },
                    mb: 8,
                  }}
                />
              </LazyLoad>
            ) : (
              <Typography>{elem.company}</Typography>
            )}
          </ButtonBase>
        ))}
      </Box>
    </SectionContainer>
  );
}
