import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Typography } from "@mui/material";
import SectionContainer from "../ui/SectionContainer";

import EventVideo from "../video/EventVideo";
import Paragraph from "../ui/Paragraph";
import { useSelector } from "react-redux";

export default function VideoPromoSection() {
  const { language } = useSelector((state) => state.i18n);

  const {
    eveema: { sectionVideoPromo },
  } = useStaticQuery(graphql`
    query videoPromoSectionQuery {
      eveema {
        sectionVideoPromo {
          backgroundColor
          backgroundImage
          text {
            de
            en
          }
          title {
            de
            en
          }
          url
          textVariant
          backupImage
        }
      }
    }
  `);
  const {
    backgroundColor,
    backgroundImage,
    backupImage,
    text,
    title,
    url,
    textVariant,
  } = sectionVideoPromo;

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        width: "100%",
        backgroundColor: (theme) =>
          backgroundColor === "dark"
            ? theme.color.gray[400]
            : theme.color.white,
      }}
    >
      <SectionContainer>
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexBasis: { xs: "100%", sm: "45%" },
              display: "flex",
              justifyContent: "center",
              mb: { xs: 4, sm: 0 },
            }}
          >
            {url && (
              <EventVideo
                url={url}
                progressBackgroundColor={textVariant}
                isSquare
              />
            )}
            {!url && backupImage && (
              <Box
                src={backupImage}
                sx={{
                  width: { xs: 250, sm: 300, lg: 350 },
                  height: { xs: 250, sm: 300, lg: 350 },
                  overflow: "hidden",
                  position: "relative",
                  "&:before": {
                    content: "''",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    display: "block",
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${backupImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    filter: "blur(3px)",
                    transform: "scale(1.1)",
                  },
                  "&:after": {
                    content: "''",
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    display: "block",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0,0,0,.5)",
                  },
                }}
              />
            )}
          </Box>
          <Box
            sx={{
              flexBasis: { xs: "100%", sm: "50%" },
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                color: (theme) =>
                  textVariant === "light"
                    ? theme.color.white
                    : theme.palette.text.primary,
              }}
            >
              {title[language]}
            </Typography>
            <Paragraph text={text[language]} textVariant={textVariant} />
          </Box>
        </Box>
      </SectionContainer>
    </Box>
  );
}
