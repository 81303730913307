import * as React from "react";
import {
  useMediaQuery,
  MobileStepper,
  Container,
  IconButton,
  Box,
} from "@mui/material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import SwipeableViews from "react-swipeable-views";
import Slider1 from "./Slider1";
import Slider2 from "./Slider2";
import Slider3 from "./Slider3";

export default function SliderSection() {
  const breakpointMd = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        mt: 2,
        height: { xs: 850, sm: 500, md: 600 },
        width: "100%",
        background: (theme) =>
          `linear-gradient(180deg, #FFF 90%, ${theme.palette.primary.main} 10%)`,
      }}
    >
      <Container
        sx={{
          height: { xs: "95%", md: "100%" },
          display: "flex",
          alignItems: "center",
          pt: 4,
        }}
      >
        <SwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          style={{ height: "100%" }}
          containerStyle={{ height: "100%" }}
        >
          <Slider1 />
          <Slider3 />
          <Slider2 />
        </SwipeableViews>
      </Container>

      <Box
        sx={{
          width: "100%",
          background: { xs: "transparent", sm: (theme) => theme.color.white },
          mt: { xs: 0, md: "-100px" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          <IconButton
            onClick={handleBack}
            disabled={activeStep === 0}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            sx={{
              maxWidth: 100,
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              background: {
                xs: "transparent",
                md: (theme) => theme.color.white,
              },
              "& .MuiMobileStepper-dot": {
                width: 12,
                height: 12,
                m: "0 5px",
              },
              "& .MuiMobileStepper-dotActive": {
                backgroundColor: (theme) =>
                  breakpointMd ? theme.palette.primary.main : theme.color.white,
              },
            }}
          />
          <IconButton
            onClick={handleNext}
            disabled={activeStep === 2}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Container>
      </Box>
    </Box>
  );
}
