import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Typography, Container, Box } from "@mui/material";
import { useSelector } from "react-redux";

export default function TeamSection() {
  const { language } = useSelector((state) => state.i18n);
  const {
    eveema: { sectionTeam },
  } = useStaticQuery(graphql`
    query sectionTeamQuery {
      eveema {
        sectionTeam {
          title {
            de
            en
          }
          image1
          image2
          image3
          image4
          link1
          link2
          link3
          link4
        }
      }
    }
  `);

  const team = [
    {
      img: (
        <Box
          component="img"
          src={sectionTeam.image1}
          alt="Jens"
          sx={{ width: "100%", height: "100%" }}
        />
      ),
      name: "Jens",
      link: sectionTeam.link1,
    },
    {
      img: (
        <Box
          component="img"
          src={sectionTeam.image2}
          alt="Karsten"
          sx={{ width: "100%", height: "100%" }}
        />
      ),
      name: "karsten",
      link: sectionTeam.link2,
    },
    {
      img: (
        <Box
          component="img"
          src={sectionTeam.image3}
          alt="Winfried"
          sx={{ width: "100%", height: "100%" }}
        />
      ),
      name: "winfried",
      link: sectionTeam.link3,
    },
    {
      img: (
        <Box
          component="img"
          src={sectionTeam.image4}
          alt="Felix"
          sx={{ width: "100%", height: "100%" }}
        />
      ),
      name: "felix",
      link: sectionTeam.link4,
    },
  ];
  return (
    <Box
      sx={{
        pt: { xs: 5, lg: 10 },
        pb: { xs: 5, lg: 20 },
        width: "100%",
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Container
        sx={{
          height: "100%",
        }}
      >
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: { xs: 16, sm: 28, md: 42 },
            lineHeight: "42px",
            mb: 6,
            color: (theme) => theme.color.white,
          }}
        >
          {sectionTeam.title[language]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "no-wrap" },
            justifyContent: "space-between",
          }}
        >
          {team.map((elem, idx) => (
            <Box
              component="a"
              href={elem.link}
              target="_blank"
              rel="noopener noreferrer"
              key={`team ${idx}`}
              sx={{
                flexBasis: { xs: "48%", md: "20%" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: { xs: 2, md: 0 },
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <Box
                sx={{
                  width: { xs: 150, sm: 250, md: 200, lg: 250 },
                  height: { xs: 150, sm: 250, md: 200, lg: 250 },
                  borderRadius: "50%",
                  overflow: "hidden",
                }}
              >
                {elem.img}
              </Box>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
}
