import * as React from "react"
import PropTypes from "prop-types"
import Container from "./Container"

export default function SectionContainer({ children }) {
  return (
    <Container sx={{ py: { xs: 6, sm: 10, lg: 12 } }}>{children}</Container>
  )
}

SectionContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
}
