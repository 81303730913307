import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Box, Typography } from "@mui/material";
import LazyLoad from "react-lazyload";

import Paragraph from "../ui/Paragraph";
import SectionContainer from "../ui/SectionContainer";
import { useSelector } from "react-redux";

export default function BrandOverviewSection() {
  const { language } = useSelector((state) => state.i18n);

  const {
    eveema: { sectionBrandOverview },
  } = useStaticQuery(graphql`
    query brandOverviewSectionQuery {
      eveema {
        sectionBrandOverview {
          backgroundImage
          image
          text {
            de
            en
          }
          title {
            de
            en
          }
          subtitle {
            de
            en
          }
          subtitleColor
          textVariant
        }
      }
    }
  `);
  const {
    backgroundImage,
    image,
    text,
    title,
    subtitle,
    subtitleColor,
    textVariant,
  } = sectionBrandOverview;

  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <SectionContainer>
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexBasis: { xs: "100%", sm: "60%", md: "45%" },
              mb: { xs: 4, md: 0 },
            }}
          >
            <Box sx={{ mb: 4 }}>
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  color: (theme) =>
                    textVariant === "light"
                      ? theme.color.white
                      : theme.palette.text.primary,
                }}
              >
                {title[language]}
              </Typography>
              {subtitle && (
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{
                    color: (theme) =>
                      subtitleColor
                        ? theme.palette[subtitleColor].main
                        : textVariant === "light"
                        ? theme.color.white
                        : theme.palette.text.primary,
                  }}
                >
                  {subtitle[language]}
                </Typography>
              )}
            </Box>
            <Paragraph text={text[language]} textVariant={textVariant} />
          </Box>
          <Box
            sx={{
              flexBasis: { xs: "100%", sm: "40%", md: "45%" },
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LazyLoad>
              <Box
                src={image}
                alt="brand promo"
                component="img"
                sx={{
                  maxHeight: { xs: 250, md: 350, lg: 450 },
                  width: "auto",
                  height: "auto",
                }}
              />
            </LazyLoad>
          </Box>
        </Box>
      </SectionContainer>
    </Box>
  );
}
