import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "../hero/Hero";
import AboutSection from "../sections/AboutSection";
import BrandOverviewSection from "../sections/BrandOverviewSection";
import SliderSection from "../sections/SliderSection";
import SupporterSection from "../sections/SupporterSection";
import TeamSection from "../sections/TeamSection";
import VideoPromoSection from "../sections/VideoPromoSection";

export default function Homepage({ supporters }) {
  const {
    eveema: { homepage },
  } = useStaticQuery(graphql`
    query homepageSectionsQuery {
      eveema {
        homepage
      }
    }
  `);

  const sections = [];
  homepage.forEach((elem) => {
    if (elem === "sectionAbout") {
      sections.push({ key: 0, element: <AboutSection /> });
    }
    if (elem === "sectionSlider") {
      sections.push({ key: 1, element: <SliderSection /> });
    }
    if (elem === "sectionTeam") {
      sections.push({ key: 2, element: <TeamSection /> });
    }
    if (elem === "sectionVideoPromo") {
      sections.push({ key: 3, element: <VideoPromoSection /> });
    }
    if (elem === "sectionBrandOverview") {
      sections.push({ key: 4, element: <BrandOverviewSection /> });
    }
    if (elem === "sectionSupporters") {
      sections.push({
        key: 5,
        element: <SupporterSection supporters={supporters} />,
      });
    }
  });

  return (
    <>
      <Hero />

      {sections.map((elem) => (
        <React.Fragment key={elem.key}>{elem.element}</React.Fragment>
      ))}
    </>
  );
}
