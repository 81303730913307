import * as React from "react";
import Homepage from "../components/homepage/Homepage";
import Newsletter from "../components/newsletter/Newsletter";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout>
      <Homepage />
      <Newsletter />
    </Layout>
  );
};

export default IndexPage;
