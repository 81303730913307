import * as React from "react"
import PropTypes from "prop-types"
import { useMediaQuery, Container as MuiContainer } from "@mui/material"

export default function Container({ children, ...props }) {
  const breakpointUp = useMediaQuery(theme => theme.breakpoints.up("xl"))
  return (
    <MuiContainer disableGutters={breakpointUp} {...props}>
      {children}
    </MuiContainer>
  )
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
}
