import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  // FormControlLabel,
  Typography,
  // TextField,
  Container,
  // Checkbox,
  // Button,
  // Alert,
  Box,
} from "@mui/material";
import InnerHtml from "./InnerHtml";
import { useSelector } from "react-redux";
// import validate from "./LoginFormValidationRules";
// import { send } from "emailjs-com";

export default function Newsletter() {
  const { language } = useSelector((state) => state.i18n);

  const {
    eveema: { widgetNewsletter },
  } = useStaticQuery(graphql`
    query newsletterQuery {
      eveema {
        widgetNewsletter {
          title {
            de
            en
          }
          subtitle {
            de
            en
          }
          buttonText {
            de
            en
          }
        }
      }
    }
  `);
  // const [errors, setErrors] = React.useState({});
  // const [values, setValues] = React.useState({
  //   name: "",
  //   email: "",
  // });
  // const [state, setState] = React.useState({
  //   dataPrivacy: false,
  //   freeAccount: false,
  // });
  // const [dataPrivacyError, setDataPrivacyError] = React.useState(false);
  // const [successAlert, setSuccessAlert] = React.useState(false);
  // const [errorAlert, setErrorAlert] = React.useState(false);

  // const handleCheckboxChange = (event) => {
  //   setState({
  //     ...state,
  //     [event.target.name]: event.target.checked,
  //   });
  //   if (event.target.name === "dataPrivacy") {
  //     setDataPrivacyError(false);
  //   }
  // };

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };

  // const handleSubmit = (e) => {
  //   if (e) e.preventDefault();
  //   setErrors(validate(values));
  //   if (state.dataPrivacy === false) {
  //     setDataPrivacyError(true);
  //   }
  //   if (errors !== null && state.dataPrivacy === true) {
  //     send(
  //       "service_syk04n8",
  //       "template_hd8r1g4",
  //       {
  //         email: values.email,
  //         name: values.name,
  //         newsletter: state.freeAccount,
  //       },
  //       "user_KhjQgCuFDavRsiHLihfEe"
  //     )
  //       .then((response) => {
  //         setValues({
  //           name: "",
  //           email: "",
  //         });
  //         setState({
  //           dataPrivacy: false,
  //           freeAccount: false,
  //         });
  //         setErrors({});
  //         setDataPrivacyError(false);
  //         setTimeout(() => {
  //           setSuccessAlert(false);
  //         }, 8000);
  //         setSuccessAlert(true);
  //       })
  //       .catch((err) => {
  //         setTimeout(() => {
  //           setErrorAlert(false);
  //         }, 8000);
  //         setErrorAlert(true);
  //       });
  //   }
  // };

  // const inputs = [
  //   { value: "name", label: "Name" },
  //   { value: "email", label: "E-mail" },
  // ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: (theme) => theme.palette.primary.main,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          background: "rgba(255,255,255,.1)",
          width: { xs: "100%", sm: "95%", xl: 1400 },
          borderRadius: { xs: 0, sm: 20, md: 50 },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            my: { xs: 0, xl: 6 },
            p: { xs: 8, xl: 0 },
          }}
        >
          <Box
            sx={{
              flexBasis: { xs: "100%", md: "48%" },
            }}
          >
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: { xs: 16, sm: 28, md: 42 },
                lineHeight: "42px",
                mb: 2,
                color: (theme) => theme.color.white,
              }}
            >
              {widgetNewsletter.title[language]}
            </Typography>

            <Typography
              sx={{
                color: (theme) => theme.color.white,
              }}
            >
              {widgetNewsletter.subtitle[language]}
            </Typography>
          </Box>
          <Box
            sx={{
              flexBasis: { xs: "100%", md: "48%" },
            }}
          >
            <InnerHtml />
            {/* <Box
              component="form"
              noValidate
              autoComplete="off"
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 6,
                }}
              >
                {inputs.map((elem) => (
                  <TextField
                    key={`input${elem.value}`}
                    id={elem.value}
                    label={`Ihre ${elem.label}`}
                    variant="standard"
                    color="info"
                    error={errors[elem.value] ? true : false}
                    helperText={errors[elem.value]}
                    value={values[elem.value]}
                    onChange={handleChange(elem.value)}
                    sx={{
                      width: { xs: "100%", sm: "49%" },
                      ".MuiInputLabel-root": {
                        color: (theme) => theme.color.white,
                      },
                      ".MuiInput-root": {
                        color: (theme) => theme.color.white,
                      },
                      ".MuiInput-root:before": {
                        borderBottom: (theme) =>
                          `1px solid ${theme.color.white}`,
                      },
                    }}
                  />
                ))}
              </Box>

              <FormControlLabel
                sx={{
                  width: "100%",
                  color: (theme) =>
                    dataPrivacyError
                      ? theme.palette.error.main
                      : theme.color.white,
                }}
                control={
                  <Checkbox
                    checked={state.dataPrivacy}
                    onChange={handleCheckboxChange}
                    name="dataPrivacy"
                    color="info"
                    sx={{
                      color: (theme) =>
                        dataPrivacyError
                          ? theme.palette.error.main
                          : theme.color.white,
                      p: "4px",
                    }}
                  />
                }
                label="Ich stimme den Datenschutzrichtlinien zu"
              />
              <FormControlLabel
                sx={{
                  width: "100%",
                  mb: 6,
                  color: (theme) => theme.color.white,
                }}
                control={
                  <Checkbox
                    checked={state.freeAccount}
                    onChange={handleCheckboxChange}
                    name="freeAccount"
                    color="info"
                    sx={{
                      color: (theme) => theme.color.white,
                      p: "4px",
                      "& label.Mui-focused": {
                        color: "green",
                      },
                    }}
                  />
                }
                label="Ich möchte den Newsletter abonnieren"
              />
              <Box sx={{ textAlign: "right", width: "100%" }}>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  variant="contained"
                  type="submit"
                  sx={{ color: (theme) => theme.color.white, px: 6 }}
                >
                  Anmelden
                </Button>
                {successAlert && (
                  <Alert severity="success">
                    Email wurde erfolgreich Versendet!
                  </Alert>
                )}
                {errorAlert && (
                  <Alert severity="error">
                    Es ist ein Problem aufgetreten. Bitte versuchen Sie es
                    später erneut.
                  </Alert>
                )}
              </Box>
            </Box> */}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
