import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Typography,
  Container,
  // Button,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";

export default function Hero() {
  const { language } = useSelector((state) => state.i18n);

  const {
    eveema: { hero },
  } = useStaticQuery(graphql`
    query heroQuery {
      eveema {
        hero {
          title {
            de
            en
          }
          subtitle {
            de
            en
          }
          text {
            de
            en
          }
          image
          imageMobile
        }
      }
    }
  `);

  return (
    <Box
      sx={{
        height: "calc(100vH - 64px)",
        width: "100%",
        background: (theme) => theme.palette.primary.main,
      }}
    >
      <Box sx={{ position: "relative", height: "100%" }}>
        <Container
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            height: "100%",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              flexBasis: {
                xs: "100%",
                md: "45%",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                textAlign: { xs: "left", md: "center" },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 800,
                  fontSize: { xs: 20, sm: 28, md: 42 },
                  lineHeight: "42px",
                  mb: { xs: 0, sm: 2 },
                  color: (theme) => theme.color.white,
                }}
              >
                {hero.title[language]}
              </Typography>
              <Typography
                sx={{
                  mb: { xs: 0, md: 2 },
                  fontFamily: "Marck Script",
                  fontSize: 18,
                  color: (theme) => theme.color.white,
                }}
              >
                {hero.subtitle[language]}
              </Typography>

              <Box
                sx={{
                  display: { xs: "flex", md: "none" },
                  justifyContent: "center",
                  alignItems: "center",
                  height: 350,
                  mb: 1,
                }}
              >
                <Box
                  component="img"
                  src={hero.imageMobile}
                  alt="network of people"
                  sx={{ height: "100%" }}
                />
              </Box>

              <Typography
                sx={{
                  mb: 6,
                  color: (theme) => theme.color.white,
                }}
              >
                {hero.text[language]}
              </Typography>

              {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => console.log("do something")}
                sx={{
                  width: "49%",
                  color: (theme) => theme.color.white,
                }}
                variant="contained"
                color="secondary"
              >
                Demo Ansehen
              </Button>
              <Button
                onClick={() => console.log("do something")}
                variant="outlined"
                sx={{
                  width: "49%",
                  color: (theme) => theme.color.white,
                  borderColor: (theme) => theme.color.white,
                  "&:hover": {
                    borderColor: (theme) => theme.color.white,
                    background: "rgba(255,255,255,.3)",
                  },
                }}
              >
                Kostenlos anmelden
              </Button>
            </Box>*/}
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            width: "100%",
            display: { xs: "none", md: "flex" },
            justifyContent: "center",
          }}
        >
          <Box component="img" src={hero.image} alt="network of people" />
        </Box>
      </Box>

      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          height: 50,
          mt: "-50px",
        }}
      >
        <KeyboardArrowDownIcon
          aria-label="more below"
          sx={{
            width: 48,
            height: 48,
            color: (theme) => theme.color.white,
          }}
        />
      </Container>
    </Box>
  );
}
