import * as React from "react";

// InnerHTML component
function InnerHtml() {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "9269554",
          formId: "0a9541e6-e38b-48e9-bf4a-1ba682c7bbf2",
          target: "#hubspotForm",
        });
      }
    });
    document.body.appendChild(script);
  }, []);

  return <div id="hubspotForm"></div>;
}

export default InnerHtml;
