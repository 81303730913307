import * as React from "react";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";

export default function VideoProgress({ value, progressBackgroundColor }) {
  const { language } = useSelector((state) => state.i18n);

  return (
    <LinearProgress
      variant="determinate"
      value={value}
      aria-label={language === "de" ? "Videodauer" : "video duration"}
      sx={{
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: (theme) =>
            progressBackgroundColor === "dark"
              ? theme.color.gray[400]
              : theme.color.white,
          opacity: 0.5,
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: (theme) => theme.palette.primary.main,
        },
      }}
    />
  );
}

VideoProgress.propTypes = {
  value: PropTypes.number.isRequired,
  progressBackgroundColor: PropTypes.string,
};
