import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Typography, Box, Container } from "@mui/material";
import { useSelector } from "react-redux";

export default function About() {
  const { language } = useSelector((state) => state.i18n);
  const {
    eveema: { sectionAbout },
  } = useStaticQuery(graphql`
    query sectionAboutQuery {
      eveema {
        sectionAbout {
          title {
            de
            en
          }
          text {
            de
            en
          }
          image
        }
      }
    }
  `);

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: { xs: "flex", sm: "none" },
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <Box
          src={sectionAbout.image}
          alt="filming event"
          component="img"
          sx={{ width: "100%", height: "auto" }}
        />
      </Box>
      <Box
        sx={{
          height: { xs: 400, md: 600 },
          width: "100%",
          position: "relative",
          background: (theme) =>
            `linear-gradient(to bottom, ${theme.palette.primary.main} 50%, #fff 50%)`,
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              flexBasis: { xs: "100%", sm: "60%", md: "45%" },
              zIndex: 20,
            }}
          >
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: { xs: 16, sm: 28, md: 42 },
                lineHeight: "42px",
                mb: 2,
                color: (theme) => theme.color.white,
              }}
            >
              {sectionAbout.title[language]}
            </Typography>

            <Typography
              sx={{
                mb: 6,
                color: (theme) => theme.color.white,
              }}
            >
              {sectionAbout.text[language]}
            </Typography>
          </Box>
        </Container>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: { xs: "100%", sm: "60%", md: "50%" },
            justifyContent: "center",
            borderTopRightRadius: 150,
            borderBottomRightRadius: 150,
            background: (theme) => theme.palette.primary.main,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            width: { xs: "0%", sm: "40%", md: "50%" },
            display: { xs: "none", sm: "flex" },
            justifyContent: "center",
            borderTopLeftRadius: 150,
            borderBottomLeftRadius: 150,
            overflow: "hidden",
          }}
        >
          <Box
            src={sectionAbout.image}
            alt="filming event"
            component="img"
            sx={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Box>
    </>
  );
}
