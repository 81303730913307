import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function PlaySoloIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 423.778 508.533">
      <path
        d="M409.473,223.677L40.788,4.238C36.285,1.593,31.649,0,26.352,0C11.919,0,0.132,11.919,0.132,26.487H0v455.56h0.132
	c0,14.567,11.787,26.486,26.22,26.486c5.432,0,9.933-1.854,14.833-4.503l368.288-219.171c8.739-7.284,14.306-18.275,14.306-30.591
	C423.778,241.949,418.212,231.093,409.473,223.677z"
      />
    </SvgIcon>
  );
}
